// import Link from 'next/link'
import { Public, Meta, P } from '../lib/ui';

const Content = () => {
  return <>
    <P>This page doesn't really exist.</P>
  </>
}

export default function SignUp() {
  return (
    <>
      <Meta title="404 - Page Not Found" />
      <Public.Header />
      <Public.Body title="404 - Page Not Found" width="max-w-4xl text-center">
        <div className="prose prose-sm sm:prose md:prose-md lg:prose-lg">
          <Content />
        </div>
      </Public.Body>
    </>
  );
}

SignUp.Layout = Public;